'use strict';

var util = {

    /**
     * @function 
     * @description Initializes recaptcha form passthrough to allow recaptcha token to be obtained before triggering form submission (to allow compatibility with formModel) for non-ajax forms
     * @param {string} formSelector The form selector
     * @param {string} recaptchaSubmit The recaptcha replacement submit button selector
     * @param {string} submitButton The hidden actual form submit button selector
     */
    registerRecaptchaFormPassthrough: function (formSelector, recaptchaSubmit, submitButton) {
        $(formSelector + ' input').on('keypress', function(e) {
            if (e.which === 13) { // detect attempted form submission by enter key
                let $form = $(this).parents(formSelector);
                let $recaptchaSubmit = $form.find(recaptchaSubmit);
                if ($recaptchaSubmit.length) {
                    e.preventDefault();
                    $recaptchaSubmit.trigger('click');
                }
            }
        });
        $(formSelector + ' ' + recaptchaSubmit).on('click', function(e) {
            var $form = $(this).parents(formSelector);
            grecaptcha.execute(
                $form.find('#recaptchaSiteKey').val(),
                {
                    action: $form.find('#recaptchaAction').val()
                }
            ).then(function (token) {
                $form.find('#recaptchaToken').val(token);
                let $submitButton = $form.find(submitButton);
                $submitButton.removeAttr('disabled');
                $submitButton.trigger('click');
            });
        });

        $(formSelector).addClass('recaptcha-passthrough-enabled');
    }
};
module.exports = util;
