'use strict';

/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
 */
var show = function (container) {
    var target = (!container || $(container).length === 0) ? $('body') : $(container);
    if (target.find('> .loader').length === 0) { // new loader
        let $loader = $('<div/>').addClass('loader');
        return $loader.appendTo(target).show();
    } else if (target.length !== target.find('> .loader').length) { // loader exists but mismatches target (e.g. target length changed since loaders were added)
        target.find('> .loader').remove();
        let $loader = $('<div/>').addClass('loader');
        return $loader.appendTo(target).show();
    } else { // loader already exists, just show it
        return target.find('> .loader').show();
    }
};
/**
 * @function
 * @description Hides an AJAX-loader
 */
var hide = function (container) {
    if (container) {
        let target = (!container || $(container).length === 0) ? $('body') : $(container);
        $(target).find('> .loader').hide();
    } else {
        $('.loader').hide();
    }
};

exports.show = show;
exports.hide = hide;
