'use strict';

var util = require('../../util'),
    recaptchaUtils = require('../../recaptcha-utils');

function initializeEvents() {
    util.initSelectCountry($('#RegistrationForm'));
    if($('#getStateCodeValFromOrderConfirmPage').length > 0) {
        $('#dwfrm_profile_address_states_state option[value="' + $('#getStateCodeValFromOrderConfirmPage').val() + '"]').attr('selected','selected');
    }

    recaptchaUtils.registerRecaptchaFormPassthrough('form.recaptcha-checkout-registration-form', 'button[type="recaptcha-submit"]', 'button.submit-requiring-recaptcha');
}

var orderconfirmation = {
    init: function () {
        initializeEvents();
    }
};

module.exports = orderconfirmation;